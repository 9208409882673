<section id="team">
    <div class="intro-box clr-col-10 clr-col-md-7">
        <h2>Our Team</h2>
        <div class="clr-row">
            <div class="clr-col-lg-2 clr-col-md-2 clr-col-4">
                <div class="card ">
                    <div class="card-img">
                        <img src="../../../assets/aglitch.png" />
                    </div>
                    <div class="card-block">
                        <h3 class="card-header">aGlitch</h3>
                        <div class="card-text">Founder of Moon Snack Games.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>