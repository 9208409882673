import { NgModule } from '@angular/core';

import { LandingRoutingModule } from './landing-routing.module';

import { LandingComponent } from './landing.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { DefaultComponent } from './default/default.component';
import { ClarityModule } from '@clr/angular';
import { GamesComponent } from './games/games.component';
import { TeamComponent } from './team/team.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    LandingComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    DefaultComponent,
    GamesComponent,
    TeamComponent,
  ],
  imports: [LandingRoutingModule, ClarityModule, CommonModule],
  providers: [],
})
export class LandingModule {}
