import { Component } from '@angular/core';

@Component({
  selector: 'games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss'],
})
export class GamesComponent {
  games = [
    {
      name: 'Ad Wars',
      description:
        'Ad Wars is a multi-genre adventure where you travel all over the Internet to destroy ads once and for all',
      image: '../../../assets/aw.png',
      link: 'https://store.steampowered.com/app/1554050/Ad_Wars/',
    },
  ];

  constructor() {}
}
