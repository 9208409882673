<section id="games">
    <div class="intro-box clr-col-10 clr-col-md-7">
        <h2>Our Games</h2>
        <div class="clr-row" style="justify-content: center;">
            <div class="clr-col-lg-5 clr-col-md-5" *ngFor="let game of games">
                <a [href]="game.link" target="_blank">
                    <div class="card">
                        <div class="card-img" style="border-radius: 15px;">
                            <img [src]="game.image" style="border-radius: 15px;" />
                        </div>
                        <div class="card-block">
                            <h3 class="card-title">{{game.name}}</h3>
                            <div class="card-text">{{game.description}}</div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>