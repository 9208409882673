<footer style="display: flex; justify-content: center;">
    <div class="clr-row clr-col-md-7 footer-content">
        <p class="company-name">
            Moon Snack Games {{year}}
        </p>
        <ul class="socials">
            <li><a href="https://twitter.com/moonsnackgames" target="_blank"><img src="assets/twitter.png"
                        width="40px" /></a>
            </li>
            <li><a href="https://www.facebook.com/moonsnackgames/" target="_blank"><img src="assets/facebook.png"
                        width="40px" /></a></li>
            <li><a href="https://www.instagram.com/moonsnackgames/" target="_blank"><img src="assets/instagram.png"
                        width="40px" /></a></li>
            <li><a href="https://www.youtube.com/channel/UCE371RpIvg_KrF4QaeHMJLQ" target="_blank"><img
                        src="assets/youtube.png" width="40px" /></a>
        </ul>
    </div>
</footer>